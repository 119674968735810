#root {
  height: 100%;
}

b, strong {
  font-family: SupremaSemiBold;
}

.envoi-logo {
  text-align: center;
  margin-bottom: 40px;
  img {
    height: 80px;
  }
}

.align-to-right {
  text-align: right;
}
.primary-text {
  color: #05091E;
  &.bold {
    font-family: SupremaBold;
  }
}
.page-title {
  font-size: 24px;
  line-height: 32px;
  .sub-title {
    font-size: 18px;
    line-height: 18px;
    &.normal {
      font-family: Suprema;
    }
  }
}
.descriptions {
  tr {
    td {
      padding: 4px 8px;
      vertical-align: top;
    }
    td:first-child {
      font-family: SupremaSemiBold;
    }
  }
}
.preview {
  width: 70px;
  height: 70px;
  border-radius: 4px;
  margin-right: 8px;
  margin-bottom: 8px;
  overflow: hidden;
  cursor: pointer;
  display: inline-block;
  position: relative;
  img {
    width: 100%;
  }
  .download-btn {
    position: absolute;
    bottom: 2px;
    right: 2px;
    z-index: 10;
    background: white;
    padding: 2px;
    line-height: 0;
    border-radius: 4px;
    .anticon-cloud-download {
      color: gray;
    }
  }
}
.ant-modal-body {
  .action {
    position: absolute;
  }
  .driver-app-modal-body {
    .complete-options {
      .ant-btn-block {
        margin-bottom: 10px;
      }
    }
    .skip-options {
      .ant-radio-group {
        width: 100%;
      }
      .ant-radio-wrapper {
        display: block;
        height: 25px;
        line-height: 25px;
        .EditableInput {
          position: absolute;
          top: 0;
          left: 26px;
          right: 0;
        }
      }
    }
  }
}
