.DriverAppHead {
  background: #820020;
  color: white;
  padding: 12px 8px;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 5;
  .ant-btn.left {
    position: absolute;
    color: white;
  }
  .title {
    text-align: center;
    font-family: SupremaSemiBold;
    font-size: 18px;
    height: 32px;
    line-height: 32px;;
  }
}