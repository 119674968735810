.appleAnchor {
  display: inline-block;
  overflow: hidden;
  border-radius: 13px;
  height: 50px;
}

.appleImage {
  border-radius: 13px;
  height: 50px;
}

.googleAnchor {
  display: inline-block;
  overflow: hidden;
  border-radius: 13px;
  height: 70px;
  margin-top: 10px;
}

.googleImage {
  border-radius: 13px;
  height: 70px;
  margin-top: 10px;
}

.footer {
  padding: 20px 30px;
  text-align: center;
  font-size: small;
}