.DriverShipmentForm {
  .section {
    padding: 8px;
    .name {
      font-family: SupremaSemiBold;
      font-size: 15px;
      margin-bottom: 10px;
    }
    .hint {
      margin-bottom: 10px;
    }
  }
  .action {
    position: fixed;
    background: white;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 8px 8px;
  }
}