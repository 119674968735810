.DriverApplicationPage {
  background: #eaf0f2;
  .header {
    padding: 16px;
    background: white;
    height: 64px;
    .logo {
      float: left;
      height: 32px;
      img {
        height: 100%;
      }
    }
    .question {
      float: right;
      font-family: SupremaSemiBold;
      line-height: 32px;
      .anticon {
        margin-left: 9px;
      }
    }
  }
  .content {
    background-color: #eaf0f2;
    padding-top: 48px;
    padding-bottom: 100px;
    padding-left: 16px;
    padding-right: 16px;
  }
  .steps {
    margin-bottom: 24px;
  }
  .confirm-checkbox {
    margin-bottom: 24px;
    .ant-checkbox-wrapper {
      font-size: 15px;
      color: #696b78;
    }
  }
  .group {
    margin-bottom: 24px;
    position: relative;
    &.small {
      cursor: pointer;
      margin-bottom: 0;
      border-bottom: 1px solid #f0f0f0;
      padding: 11px 0;
      .name {
        font-family: Suprema;
        font-size: 18px;
        line-height: 24px;
      }
    }
    .status-icon {
      position: absolute;
      right: 0;
      top: 28px;
      width: 16px;
      height: 16px;
      background-color: #1890ff;
      border-radius: 50%;
      border: 3px solid #bae7ff;
      &.done {
        background-color: #73d13d;
        border: 3px solid #d9f7be;
      }
    }
    .name {
      font-size: 22px;
      font-family: SupremaSemiBold;
      line-height: 28px;
      color: #05091e;
      margin-bottom: 4px;
      .ant-btn {
        vertical-align: top;
        min-width: 30px;
        height: 28px;
        line-height: 28px;
        text-align: left;
        .anticon {
          font-size: 18px;
          color: black;
        }
      }
    }
    .desc {
      font-size: 15px;
      line-height: 22px;
      color: #696b78;
    }
  }
  .video {
    margin-bottom: 24px;
    min-height: 270px;
  }
  .card-body {
    padding: 24px;
  }
  .ant-card-head {
    border-radius: 8px 8px 0 0;
  }
  .ant-card {
    border-radius: 8px;
    .ant-card-head {
      background-color: #05091e;
    }
    .ant-card-head-title {
      color: white;
      padding: 20px 0;
      font-size: 30px;
      font-family: SupremaSemiBold;
      line-height: 38px;
    }
    .ant-card-extra {
      line-height: 22px;
      font-size: 15px;
      a {
        color: white;
      }
    }
    .ant-card-body {
      padding: 0;
    }
    .ant-alert {
      border: none;
      border-radius: 0;
    }
  }
  .ant-input, .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    border-radius: 8px;
    border-color: #E3F2F7;
    background-color: #E3F2F7;
  }
  .ant-btn-primary {
    background-color: black;
    border-color: black;
    &:hover, &:active, &:focus {
      background-color: black;
      border-color: black;
    }
  }
}