.PhotoUploadInput {
  border: none !important;
  min-height: 300px;
  .ant-upload {
    padding: 0 !important;
  }
  .icon-wrapper, .photo-wrapper {
    background: #f2f2f3;
    border-radius: 2px;
    width: 100%;
    min-height: 300px;
  }
  .icon-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 50px 24px;
    .icon {
      height: 100%;
      width: 100%;
      max-height: 200px;
    }
  }
  .photo-wrapper {
    overflow: hidden;
    text-align: center;
    .photo {
      height: 100%;
      width: 100%;
      max-height: 300px;
    }
  }
}