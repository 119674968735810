.DriverAppFoot {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  border-top: 1px solid #B8C4C9;
  background: white;
  z-index: 5;
  .tab {
    padding: 10px;
    width: 50%;
    display: inline-block;
    text-align: center;
    .anticon {
      font-size: 18px;
    }
  }
}