.DriverAppShipments {
  position: relative;
  .DriverAppShipment {
    border-bottom: 1px solid #B8C4C9;
  }
  .no-shipments {
    font-family: SupremaSemiBold;
    text-align: center;
    padding-top: 40px;
  }
  .cover {
    background-color: #37474F;
    opacity: 0.1;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
  }
}