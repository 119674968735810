@import '~antd/dist/antd.less';

.ant-card-head {
  .ant-card-head-title {
    font-family: SupremaSemiBold;
  }
}

.ant-table-thead > tr > th {
  font-family: SupremaSemiBold;
}

.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  font-family: SupremaSemiBold;
}

.ant-picker, .ant-input-number {
  width: 100%;
}
.ant-drawer-footer {
  text-align: right;
}
.ant-form label {
  display: block;
  margin-bottom: 16px;
  &:last-child {
    margin-bottom: 0;
  }
}
@font-family: Suprema, Arial, sans-serif;@primary-color: #05091E;@primary-1: #c9cbd0;@form-item-label-height: 22px;@form-item-margin-bottom: 18px;@form-vertical-label-padding: 0 0 2px;@layout-header-height: 50px;@layout-header-padding: 0 20px;@layout-header-background: #05091E;