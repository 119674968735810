.DriverAppShipment {
  position: relative;
  padding: 8px 8px;
  cursor: pointer;
  .picture {
    height: 60px;
    width: 60px;
    background: #B8C4C9;
    border-radius: 8px;
    position: absolute;
    overflow: hidden;
    img {
      width: 100%;
    }
    .SKIPPED {
      font-size: 10px;
      line-height: 58px;
      text-align: center;
      font-family: SupremaSemiBold;
      color: red;
      border: 1px solid red;
      border-radius: 8px;
      background: white;
    }
  }
  .stop-number-wrapper {
    padding-left: 70px;
    .stop-number {
      margin-right: 4px;
      display: inline-block;
      width: 24px;
      height: 24px;
      line-height: 24px;
      border-radius: 50%;
      background: #F6BE41;
      text-align: center;
      color: white;
      font-family: SupremaSemiBold;
      &.COMPLETED {
        background: green;
      }
      &.SKIPPED {
        background: red;
      }
    }
  }
  .description {
    padding-left: 70px;
    padding-top: 4px;
    min-height: 60px;
    .name {
      margin-bottom: 4px;
      font-family: SupremaSemiBold;
      font-size: 16px;
    }
    .address {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  .more-info {
    padding-top: 10px;
    .field {
      display: inline-block;
      width: 50%;
      vertical-align: top;
      margin-bottom: 10px;
      &.full {
        width: 100%;
      }
      .name {
        font-family: SupremaSemiBold;
      }
      .value {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
}